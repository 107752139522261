@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Meie+Script&display=swap');
/* font-family: "Pacifico", cursive; */
/* font-family: "Bebas Neue", sans-serif; */

*{
    margin: 0;
    padding: 0;
    font-family: "Poppins",sans-serif;
    text-decoration: none;
}



body{
    max-width: 1920px;
    margin-inline: auto;
}

.container-mobile{
    background-image: url('/public/assets/bg-mobile.png')  ;
    background-repeat: no-repeat;
    background-size: cover;
}
.header-mobile{
    display: flex;
    min-height: 400px;
    display: flex;
    justify-content: space-between;
    }

.menu-btn{
    margin: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 40px;
}

.logo{
    filter: invert();
    max-height: 60px;
    margin: 20px;
}

.heading-group{
    display: flex;
   flex-direction: column;
   align-items: center;
   color: white;
   position: relative;
   bottom: 15vh;
   gap: 20px;
}

.heading-group h1{
    font-family: 'Bebas Neue',sans-serif;
    font-weight: 200;
    text-align: center;
    width: 300px;
    color: rgb(255, 255, 255);
}

.heading-group span{
    font-family: "Meie Script",sans-serif;
    font-size: 2rem;
}

.heading-btn{
    color: white;
    border: 2px solid white;
    padding: 5px 20px;
    border-radius: 10px;

}

.arrivals{
    padding-top: 20px;
}

.arrivals-title{
    font-family: "Poppins",sans-serif;
    font-weight: 300;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.475);
    border-bottom:2px solid rgba(0, 0, 0, 0.2) ;
    max-width: fit-content;
    margin-inline:auto ;
    margin-bottom:50px;
}


.card{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.suit{
    border: 1px solid black;
    border-bottom: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    max-height: 350px;
}

.suit-name{
    max-width: 260px;
    width: 100%;
    font-weight: 400;
    padding: 10px;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
    margin-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}

.collection {
    font-size: 1.2rem;
}

.discover{
    display: flex;
}

.discover-btn{
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px 45px;
    color: black;
  margin-inline:auto ;
}


.drawer a{
    color: black;
    padding: 10px 50px;
    
}

.drawer-title{
    font-family: 'Meie Script',cursive;
    font-weight: 700;
    font-size:40px;
}

.drawer-title-group{
    display: flex;
    flex-direction: column;
    margin: 10px 30px;
    font-family: "Kaushan Script", cursive;
}

.navbar-links{
    margin-top: 100px;
 display: flex;
 flex-direction: column;
 gap: 25px;

}


/* desktop view homepage  below*/

.desktop-header{
    display: flex;
    justify-content: space-between;
    margin:20px 50px;
}

.desktop-header h1{
    font-family: 'Meie Script', sans-serif;
}

.desktop-header-links{
    display: flex;
    gap: 50px;
    margin: 0px;
   
    align-items: center;
}

.desktop-header-links a{
    color: black;
    padding: 5px 20px;
    
}

.desktop-header-links a:hover{
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.385);
}

.hero{
    max-width: 100%;
    background:url('/public/assets/hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    display: flex;
    align-items: center;
    padding-left: 100px;
    height: auto;
    margin-inline:auto ;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.32);
    justify-content: space-between;
}


.hero-title{
    font-size:4.5rem;
    min-width: 500px;
    max-width:750px ;
    width: 100;
    font-family: 'Bebas Neue',sans-serif;
    font-weight: 300;
    color: white;
}

.hero p{
    font-size:1.2rem;
    min-width: 500px;
    max-width:700px ;
    opacity: 50%;
    color: white;
}

.explore-btn-desktop{
    color: white;
    border: 2px solid white;
    padding: 10px 30px;
    position: relative;
    top: 35px;
    border-radius: 10px;
}
.explore-btn-desktop:hover{
    color: black;
    background-color: white;
    transition: ease-in-out;
    transition-duration: 200ms;
}

.socials-desktop{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.socials-desktop a{
border-radius: 100%;
display: flex;
padding: 10px;
color: black;
font-size: 2rem;
background-color: white;

}

.socials-desktop a:hover{
    transform:scale(120%)
}

.instagram:hover{
background-image: linear-gradient(310deg, rgba(214,0,255,1) 0%, rgba(232,230,13,1) 100%);
color: white;
}

.facebook:hover{
    background-color: rgb(0, 128, 255);
    color: white;
}

.exp-title-large{
    font-weight: 200;
    font-size: 2rem;
    text-shadow: 5px 3px 5px rgba(0, 0, 0, 0.428);
    border-bottom: 2px solid black;
    width: fit-content;
    margin:50px auto;
    text-align: center;
}



.suits-group-large{
    display: flex;
    flex-wrap: wrap;
     justify-content: center;
    gap: 20px;
}

.suit-large{
    border: 1px solid black;
    border-top-left-radius:10px ;
    border-top-right-radius: 10px;
    border-bottom: none;
    max-height: 350px;
}

.card-container-large{
    text-align: center;
 
}
.card-container-large h2{
    font-weight: 400;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
    position: relative;
    bottom:10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
}




.collection-btn-large{
    display: flex;
    justify-content: center;
    margin:30px auto;
    background-color: black;
    width: fit-content;
    border-radius: 10px;
}

.col-btn-a{
padding: 10px 30px;
border-radius: 10px;
color: white;
}




.accordion-title h1{

gap: 30px;
font-size: 3rem;
font-weight: 200;
padding:0px 50px ;
text-align: center;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.427);
}


.arrow-down{
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.324);
}

.mission{
    margin: 0px 50px;
    display: flex;
  justify-content: space-between;
 
  border-radius: 30px;
 margin-bottom: 20px;
}

.mission h2{
margin: 10px 30px;
color: black;
font-weight: 700;
font-size: 2.5rem;

}
.mission p{
    margin: 0px 30px;
    font-size: 1.3rem;
    line-height: 50px;
    max-width: 1000px;
    color: black;
   
}

.mission img{
    border-radius: 30px;
    max-width: 500px;
}
.vision{
    margin: 0px 50px;
    display: flex;
  justify-content: space-between;
 
  border-radius: 30px;
  margin-bottom: 20px;
}

.vision h2{
margin: 10px 30px;
color: black;
font-weight: 700;
font-size: 2.5rem;

}
.vision p{
    margin: 0px 30px;
    font-size: 1.3rem;
    line-height: 50px;
    max-width: 1000px;
    color: black;
   
}

.vision img{
    border-radius: 30px;
    max-width: 500px;
}
/* ////// */

.approach{
    margin: 0px 50px;
    display: flex;
  justify-content: space-between;
 
  border-radius: 30px;
  margin-bottom: 10px;
}

.approach h2{
margin: 10px 30px;
color: black;
font-weight: 700;
font-size: 2.5rem;

}
.approach p{
    margin: 0px 30px;
    font-size: 1.3rem;
    line-height: 50px;
    max-width: 1000px;
    color: black;
   
}

.approach img{
    border-radius: 30px;
    max-width: 500px;
}

.about-menu-btn{
color: black;
margin: 20px;
background: none;
border: none;
font-size: 40px;
}

.about-logo{
    max-height: 60px;
    margin: 20px;
}


.mob-navbar{
   display: flex;
   justify-content: space-between;
 }



 .about-mobile-title{
 text-align: center;
 font-weight: 200;
 text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.5);

 color: black;
 padding: 10px 0px;
 font-size: 2.5rem;
 
 }

 .abt-cont{
    display: flex;
    flex-direction: column;
    margin: 30px;
 }

 .abt-cont h2{
    color:black;
    font-family: "Meie Script", sans-serif;
    font-size: 2rem;
   
    
 }

.line{
    margin: 20px 0px;
    height: 7px;
    max-width: 100%;
    width: 20%;
    background:linear-gradient(90deg,#146C94, white);
}

.abt-cont p{
    font-style:italic ;
}

.footer-container{
    margin-top: 50px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.footer{
margin: 20px;
display: flex;
flex-direction: column;
gap: 20px;
}
.footer a {
    padding-inline: 20px;
    font-size: 1.2rem;
}
.footer h2{
    color: white;
    font-size: 1.2rem;
    padding: 20px 0px;
    font-weight: 300;
}

.footer .wts-message{
    text-decoration: none;
    border: 1px solid white;
    font-size: 1.2rem;
   color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    max-width: 400px;
 
}



.instagram-dm{
    color: white;
    text-decoration: none;
    font-size: 1.7rem;
    text-decoration: none;
    border: 1px solid white;
    color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
    max-width: 400px;
}

.telephone{
    color: white;
    text-decoration: none;
    font-size: 1.6rem;
    text-decoration: none;
    border: 1px solid white;
    color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
    max-width: 400px;
}

.footer-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
    font-size: 1rem;
    margin: 50px;
}

.footer-info h1{
    font-family:'Meie Script',sans-serif ;
    font-weight: 200;
}

.footer-title{
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-logo{
    width: 80px;
    filter: invert();
}
@media only screen and (max-width: 768px) {
.footer{
    display: none;
}

.footer-container{
    text-align: center;
    margin-top: 20px;
    font-weight: 200;
}

.footer-info{
    font-size: 1rem;
}
  }


  /* telephone */
  @media only screen and (max-width: 768px) {   
    .form-container img{
        display: none;
    }
    .contact-container h1{
        font-weight: 300;
        text-shadow: 2px 2px 2px rgb(140, 140, 140);
        border-bottom: 1px solid black;
        width: fit-content;
        margin: 0 auto;
        padding: 0px 30px;
    }
form{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10%;
    background-color: rgb(233, 233, 233);
    padding: 5%;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.439);
}

form label{
    margin-top: 10px;
}

form input{
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 10px;
}

form textarea{
    border: none;
    padding: 10px;
    border-radius: 10px;
}

form button{
margin-top: 20px;
padding: 10px;
background-color: black;
color: white;
border: none;
outline: none;
border-radius: 10px;

}

}

@media only screen and (min-width:768px) {
    
.contact-container h1{
 margin: 0 auto;
    font-weight: 300;
    text-shadow: 2px 2px 2px rgb(187, 225, 234);
    border-bottom: 1px solid black;
    width: fit-content;
}

form{
display: flex;
flex-direction: column;
justify-content: center;
gap: 10px;
font-size: 1.2rem;
padding:50px 100px;
border-radius: 20px;
max-width: 400px;
width: 100%;
background:linear-gradient(10deg, rgb(92, 92, 92),rgb(177, 177, 177),rgb(163, 163, 163),rgb(67, 67, 67));
margin:65.5px auto;
font-size: 1.5rem;
}
form label{
    color: white;
    
}

form input{
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
}

form textarea{
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
}

form button{
    padding: 10px 0px;
    margin-top: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 1.2rem;
}
.reach-us{
  display: none;
}

.submit-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-style: italic;
 }
 
 .submit-page h1{
     font-size: 4rem;
     
 }
 
 .submit-page h2{
     font-size: 2rem;
     color: rgb(208, 0, 0);
 }
 
 .submit-page a{
     text-decoration: none;
     color: white;
     font-size:2rem;
     margin: 30px 0px;
     padding: 10px 50px;
     background-color: black;
     display: flex;
     gap: 30px;
     border-radius: 10px;
 }
}



.reach-us{
    text-align: center;
}

.reach-us h2{
    border-bottom: 2px solid rgba(0, 0, 0, 0.21);
    margin: 20px auto;
    width: 30%;
}

.reach-us a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: white;
    background-color: black;
    max-width: fit-content;
    margin: 10px auto;
    padding: 10px 20px;
    border-radius: 10px;
}

.reach-us .instagram-dm{
    font-size: 1rem;
}

.dropdown{
    display: flex;
    gap: 10px; 
    margin: 10px;
    flex-direction: column;
    max-width: 205px;
 
}

.dropdown-btn{
    background-color: rgba(255, 255, 255, 0);
    border: none;
    outline: none;
    font-size: 1rem;
    margin-left: 5px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.dropdown a{
    border-radius: 5px;
    background-color: black;
    color: white;
}