.title{
    text-align: center;
    font-weight: 200;
    margin-top: 40px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.519);
    border-bottom: 2px solid black;
    margin-inline: 20px;
    margin-bottom: 50px;
}

.header{
    padding-top:20px;
}

.back{
    padding-inline: 20px;
    color: black;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
}

.exp-logo{
    max-height: 70px;
    margin-inline:20px ;
}

.btn-group{
    display: flex;
    justify-content: space-between;
}